import { lazy, Suspense } from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { CenteredCircularProgress } from "../shared/components/utils/CenteredCircularProgress";
import { UploadTransactionContextProvider } from "../shared/contexts/UploadTransactionContext";

const LazyLandingPageLayout = lazy(() =>
    import("../layouts/LandingPageLayout").then(({ LandingPageLayout }) => ({
        default: LandingPageLayout,
    }))
);

const LazyPageNotFound = lazy(() =>
    import("../shared/components/errors/PageNotFound").then(
        ({ PageNotFound }) => ({
            default: PageNotFound,
        })
    )
);

const LazyErroBoundaryLayout = lazy(() =>
    import("../layouts/ErrorBoundaryLayout").then(
        ({ ErrorBoundaryLayout }) => ({
            default: ErrorBoundaryLayout,
        })
    )
);

const LazyHome = lazy(() =>
    import("../pages/home/Home").then(({ Home }) => ({ default: Home }))
);

const LazyUploadMain = lazy(() =>
    import("../pages/upload/main/UploadMain").then(({ UploadMain }) => ({
        default: UploadMain,
    }))
);

const LazyUploadDetails = lazy(() =>
    import("../pages/upload/details/UploadDetails").then(
        ({ UploadDetails }) => ({
            default: UploadDetails,
        })
    )
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <LazyErroBoundaryLayout />,
        children: [
            {
                path: "/",
                element: (
                    <Suspense fallback=<CenteredCircularProgress />>
                        <LazyLandingPageLayout />
                    </Suspense>
                ),
                children: [
                    {
                        path: "",
                        element: <LazyHome />,
                    },
                    {
                        path: "upload",
                        children: [
                            {
                                path: "",
                                element: <LazyUploadMain />,
                            },
                            {
                                path: ":requestId",
                                element: (
                                    <UploadTransactionContextProvider>
                                        <LazyUploadDetails />
                                    </UploadTransactionContextProvider>
                                ),
                            },
                        ],
                    },
                    {
                        path: "*",
                        element: <LazyPageNotFound />,
                    },
                ],
            },
        ],
    },
]);

export const AppRoutes = () => {
    return <RouterProvider router={router} />;
};
