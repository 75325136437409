import { Box, Button, Stack, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import { FallbackProps } from "react-error-boundary";

export const ErrorPage = (props: FallbackProps) => {
    const { error, resetErrorBoundary } = props;
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Stack alignItems="center">
                <ErrorOutlineIcon sx={{ fontSize: 60, color: "red" }} />
                <Typography variant="h5">Something went wrong</Typography>
                <Typography sx={{ color: "gray" }}>{error.message}</Typography>
                <Button
                    variant="contained"
                    startIcon={<RefreshIcon />}
                    onClick={resetErrorBoundary}
                >
                    Refresh page
                </Button>
            </Stack>
        </Box>
    );
};
