import {
    createContext,
    useState,
    useCallback,
    useMemo,
    useContext,
    useEffect,
} from "react";
import { ThemeProvider } from "@mui/material";
import { DarkTheme, LightTheme } from "./../themes";
import Cookies from "universal-cookie";

const cookies = new Cookies();

interface IAppThemeContextData {
    isDarkTheme: boolean;
    toggleTheme: () => void;
}

interface IAppThemeProviderProps {
    children: React.ReactNode;
}

const ThemeContext = createContext({} as IAppThemeContextData);

export const useAppThemeContext = () => {
    return useContext(ThemeContext);
};

export const AppThemeProvider: React.FC<IAppThemeProviderProps> = ({
    children,
}) => {
    const [isDarkTheme, setIsDarkTheme] = useState<boolean>(false);

    useEffect(() => {
        const state = cookies.get("isDarkTheme");

        if (state === "true") setIsDarkTheme(true);
    }, []);

    useEffect(() => {
        cookies.set("isDarkTheme", isDarkTheme);
    }, [isDarkTheme]);

    const toggleTheme = useCallback(() => {
        setIsDarkTheme((current) => !current);
    }, []);

    const theme = useMemo(() => {
        if (isDarkTheme) return DarkTheme;
        return LightTheme;
    }, [isDarkTheme]);

    return (
        <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeContext.Provider>
    );
};
