import i18n from "i18next";
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from '../locale/en.json'
import ptTranslation from '../locale/pt.json'
import esTranslation from '../locale/es.json'
import zhTranslation from '../locale/zh.json'

const resources = {
  en: {
    ...enTranslation,
  },
  pt: {
    ...ptTranslation,
  },
  es: {
    ...esTranslation,
  },
  zh: {
    ...zhTranslation,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
  })

  export default i18n