import {
    Dispatch,
    FC,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    UploadRequestModel,
    UploadTransactionModel,
} from "../services/api/UploadRequest";

interface UploadTransactionContextData {
    uploadRequest?: UploadRequestModel;
    setUploadRequest: Dispatch<SetStateAction<UploadRequestModel | undefined>>;
    transaction?: UploadTransactionModel;
    setTransaction: Dispatch<
        SetStateAction<UploadTransactionModel | undefined>
    >;
    counter: number;
}

interface UploadTransactionContextProviderProps {
    children: React.ReactNode;
}

const UploadTransactionContext = createContext(
    {} as UploadTransactionContextData
);

export const UploadTransactionContextProvider: FC<
    UploadTransactionContextProviderProps
> = ({ children }: UploadTransactionContextProviderProps) => {
    const [uploadRequest, setUploadRequest] = useState<
        UploadRequestModel | undefined
    >();
    const [transaction, setTransaction] = useState<
        UploadTransactionModel | undefined
    >();
    const [counter, setCounter] = useState<number>(0);
    const timer = useRef<NodeJS.Timeout>();

    useEffect(() => {
        let value = 0;
        if (transaction) {
            value = transaction.timeOutLimit;
        }
        setCounter((prev) => {
            if (prev !== value && timer) clearTimeout(timer.current);
            return value;
        });
    }, [transaction]);
    useEffect(() => {
        if (counter > 0) {
            timer.current = setTimeout(() => setCounter(counter - 1), 1000);
        }
    }, [counter]);

    return (
        <UploadTransactionContext.Provider
            value={{
                uploadRequest: uploadRequest,
                setUploadRequest: setUploadRequest,
                transaction: transaction,
                setTransaction: setTransaction,
                counter: counter,
            }}
        >
            {children}
        </UploadTransactionContext.Provider>
    );
};

export const useUploadTransactionContext = () =>
    useContext(UploadTransactionContext);
