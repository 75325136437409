import { createTheme } from "@mui/material";

export const DarkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#0f70b7",
            light: "#2a87d3",
            dark: "#195e96",
            //main: "#21495e",
            //light: "#2d6481",
            //dark: "#142d39",
            contrastText: "white",
        },
        background: {
            paper: "#353535",
            default: "#1f1f1f",
        },
    },
    typography: {
        allVariants: {
            fontFamily: ["muli", "Segoe UI", "Roboto"].join(","),
            color: "white",
        },
        button: {
            textTransform: "none",
        },
        fontWeightBold: 700,
    },
});
