import React from "react";
import "./App.css";
import { AppRoutes } from "./routes";
import { ErrorFallback } from "./shared/components/errors/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { AppThemeProvider } from "./shared/contexts/AppThemeContext";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./shared/services";

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <AppThemeProvider>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <AppRoutes />
                </ErrorBoundary>
            </AppThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
