import { createTheme } from "@mui/material";

export const LightTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#0f70b7",
            light: "#2a87d3",
            dark: "#195e96",
            contrastText: "#eeeeee",
        },
    },
    typography: {
        allVariants: {
            fontFamily: ["muli", "Segoe UI", "Roboto"].join(","),
        },
        button: {
            textTransform: "none",
        },
        fontWeightBold: 700,
    },
    components: {},
});
